import { request } from '~/agent';

// General Chat
export const getMessages = params => {
    return request.get(`chat/messages`, params);
};

export const getMessage = (messageID, params) => {
    return request.get(`chat/messages/${messageID}`, params);
};

export const getMessageStats = params => {
    return request.get(`chat/messages/stats`, params);
};

export const createMessage = (data, params) => {
    return request.post(`chat/messages`, data, params);
};
