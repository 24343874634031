import { connect } from 'react-redux';

import PaperContainer from 'Lib/components/layout/components/PaperContainer';

const mapStateToProps = state => ({
    isImpersonated: state.app.isImpersonated,
    ui: state.ui
});

function App(props) {
    const { isImpersonated, component, ui, route } = props;
    const pinned = !!ui.pinnedContentTypeID;

    if (component) {
        return (
            <div id="default-layout">
                <div className="main">
                    <PaperContainer open={ui.menuVisible} isImpersonated={isImpersonated}>
                        <component route={route} />
                    </PaperContainer>
                </div>
            </div>
        );
    }

    return (
        <div id="default-layout">
            <div className="main" style={{ display: pinned ? 'flex' : 'block' }}>
                <PaperContainer open={ui.menuVisible} isImpersonated={isImpersonated}>
                    {props.children}
                </PaperContainer>
            </div>
        </div>
    );
}

export default connect(mapStateToProps)(App);
