/*
 * Icon library here: https://fonts.google.com/icons
 * All icons with use the same className of "material-symbols-rounded" (rounded version)
 *
 * `permissionsFlag` is only added if the item itself is only visible based off of Redux permissions.
 * e.g. Ledger item will run a permission check in `App.js` to check the value `isLiveLedgerEnabled`
 * and only render if the value is `1` or `true`; see `navigationPermissions.js` for more info there.
 */
const NAVIGATION_ITEMS = [
    {
        label: 'Ledger',
        path: '/ledger',
        icon: 'account_balance',
        permissionFlag: 'isLiveLedgerEnabled'
    },
    {
        label: 'Properties',
        path: '/properties',
        icon: 'home_work'
    },
    {
        label: 'Rent Roll',
        path: '/reports/rent-roll',
        icon: 'monitoring',
        permissionFlag: 'isRentRollEnabled'
    },
    {
        label: 'Statements',
        path: '/statements',
        icon: 'account_balance'
    },
    {
        label: 'Files',
        path: '/files',
        icon: 'home_storage'
    },
    {
        label: 'Bills',
        path: '/bills',
        icon: 'receipt_long',
        permissionFlag: 'isShowBillsEnabled'
    },
    {
        label: 'Work Orders',
        path: '/work-orders',
        icon: 'home_repair_service',
        permissionFlag: 'isWorkOrdersEnabled'
    },
    {
        label: 'Estimates',
        path: '/estimates',
        icon: 'monetization_on'
    },
    {
        label: 'Payments',
        path: '/payments',
        icon: 'payments'
    },
    {
        label: 'Chat',
        path: '/chat',
        icon: 'chat',
        badgeID: 'unreadMessageCount'
    },
    {
        label: 'Settings',
        path: '/settings',
        icon: 'manage_accounts',
        permissionFlag: 'isPublicStatementEnabled'
    }
];

export default NAVIGATION_ITEMS;
