import { Component } from 'react';
import { connect } from 'react-redux';

import AnimatedLogo from 'Lib/assets/AnimatedLogo';
import { limitLength } from 'Lib/utilities/limitLength';

import * as app from '~/services/app';

const mapStateToProps = state => ({
    app: state.app
});

class PortfolioSelected extends Component {
    onCancel = () => {
        app.cancelChangePortfolio();
    };

    onSelect = portfolio => {
        app.setPortfolio(portfolio);
        app.portfolioInitialize();
    };

    renderSelector() {
        const { portfolios } = this.props.app.portal;

        return (
            <div style={{ backgroundColor: '#DEE7E7', height: '100vh' }}>
                <div className="portfolio-select-container">
                    <div className="portfolio-select">
                        <h2>Select a Portfolio:</h2>
                        <br />
                        <ul>
                            {portfolios.map((portfolio, index) => {
                                const splitName = portfolio.name.split(' ');
                                const initials = splitName[0][0] + (splitName[1] ? splitName[1][0] : '');
                                const color = initials[0].toLowerCase();

                                return (
                                    <div key={index} className="portfolio-name">
                                        <li onClick={() => this.onSelect(portfolio)}>
                                            <div className="user-icon">
                                                <p className={`portfolio-icon-${color}`}>{initials}</p>
                                            </div>
                                            <div className="user-name">
                                                <p>{limitLength(portfolio.name, 20)}</p>
                                            </div>
                                        </li>
                                    </div>
                                );
                            })}
                        </ul>
                        <br />
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { portal, changePortfolio = false, portfolio } = this.props.app;

        if (!portal) {
            return <AnimatedLogo />;
        }

        if (changePortfolio) {
            return this.renderSelector();
        }

        if (!portfolio) {
            if (portal.portfolios.length == 0) {
                return <>You do not have access to any portfolios</>;
            }
        }

        return <>{this.props.children}</>;
    }
}

export default connect(mapStateToProps, null)(PortfolioSelected);
