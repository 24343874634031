import { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Container } from '@mui/material';

import { STATS_INTERVAL_TIMER } from 'Lib/components/chat/Chat';
import SentryErrorBoundary from 'Lib/components/errors/SentryErrorBoundary';
import Navigation from 'Lib/components/layout/Navigation';
import NotFound from 'Lib/components/layout/NotFound';
import PortalTypes from 'Lib/constants/app/portalTypes';
import ChatObjectTypes from 'Lib/constants/communications/chatObjectTypes';
import QueryClientProvider from 'Lib/QueryClient';
import navigationPermissions from 'Lib/utilities/navigationPermissions';

import PortalSelected from './PortalSelected';
import PortfolioSelected from './PortfolioSelected';

import '~/scss/app.scss';
import 'react-toastify/dist/ReactToastify.css';

import { getMessageStats } from '~/api/chat';
import NAVIGATION_ITEMS from '~/constants/layout/NavigationItems';
import Authenticated from '~/containers/Authenticated';
import AppLayout from '~/containers/layouts/App';
import routes from '~/routes';
import * as app from '~/services/app';
import * as session from '~/services/session';
import { showMenu } from '~/services/ui/actions';

const mapStateToProps = state => ({
    app: state.app,
    portalAccounts: state.app.portalAccounts,
    ui: state.ui
});

const mapDispatchToProps = {
    showMenu
};

class App extends Component {
    state = {
        items: [],
        intervalID: null,
        unreadMessageCount: 0
    };

    componentDidMount() {
        const intervalID = setInterval(() => this.getStats(), STATS_INTERVAL_TIMER);
        this.setState({ intervalID });
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.app) != JSON.stringify(this.props.app)) {
            const { portalSettings, portalOwnerSettings } = this.props.app;
            const permissions = { ...portalSettings, ...portalOwnerSettings };

            this.setState({
                items: navigationPermissions(permissions, NAVIGATION_ITEMS, {
                    isWorkOrdersEnabled: () => {
                        if (portalSettings?.isWorkOrdersEnabled == '1') {
                            return { label: 'Work Orders', path: '/work-orders', icon: 'home_repair_service' };
                        }
                    },
                    // Since public statement setting is only setting currently in the owner portal settings,
                    // we don't want to show the setting if it's not enabled by the PM.
                    // If other settings are added for owner portal this can be removed.
                    isPublicStatementEnabled: () => {
                        if (portalSettings?.isPublicStatementEnabled == '1') {
                            return { label: 'Settings', path: '/settings', icon: 'manage_accounts' };
                        }
                    }
                })
            });
        }
    }

    componentWillUnmount() {
        clearInterval(this.state.intervalID);
    }

    getStats = () => {
        getMessageStats({ chatObjectTypeID: ChatObjectTypes.Owner }).then(response => {
            if (response.data.unreadCount > 0) {
                this.setState({
                    unreadMessageCount: response.data.unreadCount
                });
            }
        });
    };

    onChangePortal = portal => {
        app.setPortal(portal);
        app.changePortfolio();
    };

    onChangePortfolio = portfolio => {
        app.setPortfolio(portfolio);
        app.portfolioInitialize();
    };

    render() {
        const { items, unreadMessageCount } = this.state;
        const { app, ui, portalAccounts } = this.props;

        return (
            <>
                <Router basename="/portals/owner">
                    <Container
                        disableGutters
                        maxWidth={false}
                        sx={theme => ({
                            bgcolor: 'common.background',
                            [theme.breakpoints.up('md')]: {
                                height: '100vh',
                                overflow: 'hidden'
                            }
                        })}
                    >
                        <Authenticated>
                            <PortalSelected>
                                <PortfolioSelected>
                                    <QueryClientProvider>
                                        <Navigation
                                            key={items}
                                            app={app}
                                            portalAccounts={portalAccounts}
                                            portalTypeID={PortalTypes.Owner}
                                            items={items}
                                            open={ui.menuVisible}
                                            session={session}
                                            unreadMessageCount={unreadMessageCount}
                                            toggleDrawer={() => this.props.showMenu(!ui.menuVisible)}
                                            onChangePortal={this.onChangePortal}
                                            onChangePortfolio={this.onChangePortfolio}
                                        />
                                        <Switch>
                                            {routes.map((route, index) => (
                                                <Route
                                                    key={index}
                                                    exact={true}
                                                    path={route.path}
                                                    render={props => {
                                                        let Layout = AppLayout;

                                                        if (route.layout) {
                                                            Layout = route.layout;
                                                        }

                                                        return (
                                                            <Layout>
                                                                <SentryErrorBoundary>
                                                                    <route.component route={props} routes={route.routes} />
                                                                </SentryErrorBoundary>
                                                            </Layout>
                                                        );
                                                    }}
                                                />
                                            ))}
                                            <Route route="*" component={NotFound} />
                                        </Switch>
                                    </QueryClientProvider>
                                </PortfolioSelected>
                            </PortalSelected>
                        </Authenticated>
                    </Container>
                </Router>
                <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnVisibilityChange
                    draggable
                    pauseOnHover
                />
            </>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
